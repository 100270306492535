import titlePic from "../../assets/images/Games/AvastYeTitle.jpg"

import "../About.css"

var game_link = <a href={"https://store.steampowered.com/app/1064170/Avast_Ye/"} target="_blank">Steam!</a>

export default function Avast()
{
    return(
    <>
        <div className="about-stripe">
            <h1 className="about-header1">Avast Ye: Antventure Be Callin!</h1>
        </div>
        <div className="about-image">
        <img
            src={titlePic}
            alt='Cover art for Avast Ye'
            style={{
                height: 250,
                width: 500,
                margin: 10
            }}
        />
        </div>
        <p className="about-text"> Avast Ye was the BYU Center for Animation's game project for 2019, for 
            which I was a gameplay programmer. It was created using Unreal Engine and is an action-adventure 
            game where the player takes the role of an ant queen turned pirate and gives command to her 
            crew in fast-paced combat. It was entered in the 2020 Intel University Games Showcase, and was
            the first ever to win 2 awards: Best Visual Quality and Best Game Play.</p>
        <p className="about-text">I won't spend much more time talking about the game itself, since you can 
            get it on {game_link} There isn't anything about the game that I can say that isn't already said
            on the Steam page and that can't be experience yourself.</p>
        <p className="about-text">As for my contributions to the project, I was one of several gameplay 
            programmers. I had no specific role that I was in charge of, but I was the guy that the directors
            went to when they wanted something implemented quickly in order to see if it would work. As a result,
            much of what I worked on is not in the final release, but was nonetheless instrumental to the 
            development of the game. Some (but by no means all) of what I implemented include:
        </p>
        <ul className="about-text">
            <li>A "Big Stick" enemy type</li>
            <li>Breaking boulders with the bull ant</li>
            <li>Raft pathing and triggers</li>
            <li>Fire ant bomb physics</li>
            <li>Many witch doctor ability prototypes (gas bomb, black hole, aphid swarm, aphid chain)</li>
            <li>An onslaught mode and enemy wave implementation</li>
            <li>Enemy dodging mechanic</li>
            <li>Enemies switching between melee and ranged weapons</li>
            <li>Combo mechanic and gold multiplier</li>
            <li>Crew Member incapacitation and revival</li>
            <li>Beetle Shield slam ability</li>
            <li>Ability upgrade system</li>
        </ul>
    </>
    )
}