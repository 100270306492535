import PageSelector from './components/PageCard.js';
import './Home.css';
import mePicture from '../assets/images/me/me.png';
import galleryPic from '../assets/images/Thumbnails/GalleryThumbnail.png';
import thesisPic from '../assets/images/Thumbnails/ThesisThumbnail.png';
import gamesPic from '../assets/images/Thumbnails/GamesThumbnail.png';
import programmingPic from '../assets/images/Thumbnails/ProgrammingThumbnail.png'

export default function Home()
{
    return (
        <>
            <div className='main-stripe'>
                <h1 className='home-header1'>Brenden Grace</h1>
                <img
                    className='profile'
                    src={mePicture}
                    alt='It is Brenden'
                    style={{
                        height: 170,
                        width: 170
                    }}
                />
                <h2 className='home-header2'>I got my B.S. in Computer Science with Animation Emphasis before going to Austria to get my Master's in Interactive Media. I quite enjoy playing and making games, but I do plenty of non-gaming and animation related programming as well.</h2>
            </div>
            <div className='cards'>
                <PageSelector title="Master's Thesis" thumbnail={thesisPic} content="'Exhausted Avatars in Virtual Reality and the Proteus Effect' examined the ways in which an avatar's exhausted appearance can affect a user's performance when cycling in virtual reality." link={'/Thesis'}></PageSelector>
                <PageSelector title='Game Projects' thumbnail={gamesPic} content='Games made as personal projects, for classes, and a game jam.' link={'/Games'}></PageSelector>
                {/* <PageSelector title='Class Projects' thumbnail={mePicture} content='This is test card 2' link={'/'}></PageSelector> */}
                <PageSelector title='Programming Projects' thumbnail={programmingPic} content="This will be for various programming projects I've worked on. Under construction." link={'/Programs'}></PageSelector>
                <PageSelector title='Gallery' thumbnail={galleryPic} content='Various models and animations, both for projects listed elsewhere on this site and other generally incomplete personal projects.' link={'/Gallery'}></PageSelector>
            </div>
        </>
    )
}