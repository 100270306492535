import video1 from "../../assets/videos/UnrealRPG.mp4"
import screenshot from "../../assets/images/Games/UnrealRPGPic.png"

import "../Thesis.css"

export default function UnrealRPG()
{
    return(
        <>
        <div className="thesis-stripe">
            <h1 className="thesis-header1">Unreal RPG</h1>
        </div>
        <div className="thesis-image">
        <img
            src={screenshot}
            alt='The title screen for the game'
            style={{
                height: 250,
                width: 500,
                margin: 10
            }}
        />
        </div>
        <p className="thesis-text">This a rough turn-based RPG system I spent some time working on in Unreal
            Engine. It allows for multiple party members and enemies to take turns taking actions, although 
            the enemies don't do anything yet. Attack, Defend, and Skill commands are implemented, with 
            appropriate targeting for offensive or recovery skills. Characters have combat stats including 
            turn speed and movement and attack range. There isn't much else to it, since I was
            just working on it on the side during school, but I thought it was pretty good for like 2 weeks
            of work in my spare time. I don't have any complete builds to share, but I guess I could if
            someone really wanted to try an unfinished RPG combat system for some reason.
        </p>
        <div className="thesis-video">
            <video src={video1}
            width='50%'
            height='50%'
            margin='30px'
            controls></video>
        </div>
        </>
    )
}