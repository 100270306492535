import './PageCard.css'

const handleClick = (link) => () => 
{
    window.location.href=link;
}

export default function PageSelector({title, thumbnail, content, link})
{
    return (
        <div className='page-card' onClick={handleClick(link)}>
            <img
                className='profile'
                src={thumbnail}
                alt={title}
                style={{
                    height: 100,
                    width: 100,
                    padding: 10
                }}
            />
            <div>
                <h1 className='card-title'>{title}</h1>
                <h2 className='card-content'>{content}</h2>
            </div>
        </div>
    )
}