import React, { useEffect, useState } from 'react'
import useWordle from '../hooks/useWordle'

// components
import Grid from './Grid'
import Keypad from './Keypad'
import Modal from './Modal'

export default function Wordle({ solution, attempts, setShowWordle }) {
  const { currentGuess, guesses, turn, isCorrect, usedKeys, handleKeyup, handleKeyupVirtual } = useWordle(solution, attempts)
  const [showModal, setShowModal] = useState(false)
  const [lockKeypad, setLockKeypad] = useState(false)
  
  useEffect(() => {
    window.addEventListener('keyup', handleKeyup)

    if (isCorrect || turn > attempts) {
      setTimeout(() => setShowModal(true), 2000)
      window.removeEventListener('keyup', handleKeyup)
      setLockKeypad(true);
    }
    else {
      window.addEventListener("keyup", handleKeyup);
      setLockKeypad(false);
    }

    return () => window.removeEventListener('keyup', handleKeyup)
  }, [handleKeyup, isCorrect, turn])

  return (
    <div>
      <Grid guesses={guesses} currentGuess={currentGuess} turn={turn} length={solution.length}/>
      <Keypad usedKeys={usedKeys} handleKeyup={handleKeyupVirtual} isLocked={lockKeypad}/>
      {showModal && <Modal isCorrect={isCorrect} turn={turn} solution={solution} setShowModal={setShowModal} setShowWordle={setShowWordle}/>}
    </div>
  )
}
