import PageSelector from './components/PageCard.js';
import './Home.css';
import avastPic from '../assets/images/Thumbnails/AvastThumbnail.png';
import magicPic from '../assets/images/Thumbnails/DeepMagicThumbnail.png';
import roombaPic from '../assets/images/Thumbnails/RoombaThumbnail.png';
import skelePic from '../assets/images/Thumbnails/GamesThumbnail.png';
import rpgPic from '../assets/images/Thumbnails/UnrealRPGThumbnail.png';
import lsglPic from '../assets/images/Thumbnails/LSGLThumbnail.png';

export default function Games()
{
    return (
        <>
            <div className='main-stripe'>
                <h1 className='home-header1'>Game Projects</h1>
                <h2 className='home-header2'>These are games where I worked on a team or made them entirely myself. Some are for classes, some are personal projects, some started in class and I kept working on them afterwards. They were all made with either Unity or Unreal Engine, and I worked on them as a programmer, modeler/animator, or both.</h2>
            </div>
            <div className='cards'>
                <PageSelector title="Avast Ye" thumbnail={avastPic} content="Avast Ye was the BYU Center for Animation's game project for 2019 and is an action-adventure game for which I was a gameplay programmer." link={'/Games/AvastYe'}></PageSelector>
                <PageSelector title='Deep Magic' thumbnail={magicPic} content="A semester project that was designed to be used in the Ars Electronica Center's Deep Space exhibit. I was the lead 3D modeler and animator." link={'/Games/DeepMagic'}></PageSelector>
                <PageSelector title="Roomba's Revenge" thumbnail={roombaPic} content="VoidSupport's Entry in the 12th Hagenberg Game Jam. I was the 3D lead." link={'/Games/RoombasRevenge'}></PageSelector>
                <PageSelector title='Skeleslaps' thumbnail={skelePic} content='Skeleslaps is not an official title. It was initially made for an advanced graphics class, but I decided to keep working on it after.' link={'/Games/Skeleslaps'}></PageSelector>
                <PageSelector title='Unreal RPG' thumbnail={rpgPic} content='A very much not complete game, this was my attempt at making a turn-based RPG system in Unreal Engine.' link={'/Games/UnrealRPG'}></PageSelector>
                {/* <PageSelector title='Action RPG' thumbnail={lsglPic} content='Under construction' link={'/Games'}></PageSelector> */}
            </div>
        </>
    )
}