import me2 from "../assets/images/me/me2.jpg"
import meclimb from "../assets/images/me/meClimb.jpg"
import mebow from "../assets/images/me/meBow.jpg"
import mesword from "../assets/images/me/meSword.jpg"
import "./About.css"

export default function About() {
    return (
        <>
        <div className="about-stripe">
            <h1 className="about-header1">About Brenden Grace</h1>
            <div className="about-image">
            <img
                src={me2}
                alt='Me when I graduated from BYU'
                style={{
                    height: "20%",
                    width: "20%",
                    margin: 10
                }}
            />
            </div>
        </div>
        <p className="about-text">What does one write in an about section of a website already dedicated to a person? I suppose I should just talk about myself as a person, rather
            than just my professional/educational experience. In case it wasn't abundantly clear based on my gallery and several of the other pages on this site, I am a big fan of 
            video games, particularly fantasy and sci-fi RPGs and strategy games. I also read books a lot when I was younger (I once read 4 of the 7 books in the <i>Apprentice Adept </i>
             series within a week). Although I haven't read much lately, primarily due to not hearing many recommendations and moving between states and I haven't gotten a new library 
            card since then.
        </p>
        <p className="about-text"> I initially wanted to write books as a kid, but I got my first taste of programming in general and game development specifically when in 7th grade
            I made a basic game using GameMaker for a book report on one of the <i>Dragonriders of Pern </i> books. It started with a very simple two-player platformer duel (like 
            <i> Super Smash Bros. </i> but worse) before transitioning to a scrolling shooter atop a dragon. I have no idea if that game exists anywhere after all of the changing 
            computers and moving houses that's occurred since then, but I'd love to find it again.
        </p>
        <p className="about-text">Anyway, in no particular order and certainly not exhaustive, here are some of my favorite media (mostly games)</p>
        <ul className="about-text">
            <li><i>The Lord of the Rings </i> movies</li>
            <li><i>Ender's Game</i></li>
            <li>The <i>Trails</i> series</li>
            <li>The <i>Tales</i> series</li>
            <li><i>Fire Emblem</i></li>
            <li><i>Civilization</i></li>
            <li><i>Super Metroid</i></li>
        </ul>
        <p className="about-text">I guess I could also talk <i>about </i> this site a bit as well. This site was made using React after I got some resume advice from a former roomate
        and saw that he had his own personal site. I figured that I should make one as well, since it seemed like it would be good experience with web development and it gives a
        better idea of what I've done than a few words on a single piece of paper could. That is, if I'd ever printed out a resume in the last decade instead of doing it digitally. 
        Obviously, this site is still a work in progress and I'll be adding more pages and projects that I've worked on as well
        as improving the overall design.</p>
        <p className="about-text">Finally, since I don't feel like rambling anymore and don't know how else to end an About page, here's some pictures of young me being impressive:</p>
        <div className="about-image">
        <img
            src={mebow}
            alt='Me doing archery out of a tree. No one was injured.'
            style={{
                height: '22%',
                width: '22%',
                margin: 15
            }}
        />
        <img
            src={meclimb}
            alt='Me climbing a rock wall with a broken arm. It was already broken when I started.'
            style={{
                height: '25%',
                width: '25%',
                margin: 15
            }}
        />
        <img
            src={mesword}
            alt='Baby me with a much-too-large sword. I do not know if anyone was injured.'
            style={{
                height: '30%',
                width: '30%',
                margin: 15
            }}
        />
        </div>
        </>
    )
}