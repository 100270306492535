import skelegif from "../../assets/images/Games/SkeleRun.gif"
import doc from "../../assets/images/Games/DeepMagicDoc.pdf"

import "../Thesis.css"
import YouTube from "react-youtube"

var doc_link = <a href={doc} target="_blank">project document.</a>
var ars_link = <a href="https://ars.electronica.art/center/en/deepspace/" target="_blank">Deep Space</a>

export default function DeepMagic()
{
    return(
        <>
        <div className="thesis-stripe">
            <h1 className="thesis-header1">Deep Magic</h1>
        </div>
        <div className="thesis-video">
            <YouTube videoId="-t5PlI3WGKY"
            width='50%'
            height='50%'
            margin='30px'
            controls></YouTube>
        </div>
        <p className="thesis-text">Deep Magic was a semester project that I was a part of while at the University
            of Applied Sciences Upper Austria with several other students from the Interactive Media program and 
            the Digital Arts program. It is essentially asymmetrical capture-the-flag meant to be played in
            the physical space of the Ars Electronica Center's {ars_link} exhibit. One player wears a VR 
            headset and casts spells by using hand gestures, while the other players try to capture pages of 
            the wizard's spellbook based on projections on the floor and wall.
        </p>
        <p className="thesis-text">The school's campus had a lab with a functional, but much smaller scale
            version of the Deep Space system which we could use to test it. As far as I know, there weren't
            any videos taken of us developing and testing in the lab, and I no longer have access to our project
            presentation video, so the gameplay footage above is the only visual I have access to besides individual
            assets.
        </p>
        <p className="thesis-text">I was the primary modeler and animator. I created the skeletons that most
            of the players embodied as well as the wizard that the VR player controlled and various other assets.
            More details about the game's overall details can be found in the {doc_link} More specific models and
            animations that I made for this game can be found on this site's Gallery.
        </p>
        <div className="thesis-image">
        <img
            src={skelegif}
            alt='Just a lil guy running'
            style={{
                height: 200,
                width: 200,
                margin: 10
            }}
        />
        </div>
        </>
    )
}