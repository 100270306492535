import PageSelector from './components/PageCard.js';
import './Home.css';
import wordle from '../assets/images/Thumbnails/ProgrammingThumbnail.png';


export default function Programs()
{
    return (
        <>
            <div className='main-stripe'>
                <h1 className='home-header1'>Programming Projects</h1>
                <h2 className='home-header2'>Various programming projects either made originally in javascript or adapted to fit this site.</h2>
            </div>
            <div className='cards'>
                <PageSelector title="Wordle" thumbnail={wordle} content="An incomplete Wordle implementation made to practice web development. Don't look too closely or I will be sad." link={'/Programs/Wordle'}></PageSelector>
            </div>
        </>
    )
}