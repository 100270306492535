import roombaPic from "../../assets/images/Games/RoombaPic.png"

import "../Thesis.css"

var game_link = <a href="https://danielk0703.itch.io/roombas-revenge" target="_blank">itch.io page.</a>
var jam_link = <a href="https://hagenberg-gamejam.at/2023/" target="_blank">here.</a>

export default function Roomba()
{
    return(
        <>
        <div className="thesis-stripe">
            <h1 className="thesis-header1">Roomba's Revenge</h1>
        </div>
        <div className="thesis-image">
        <img
            src={roombaPic}
            alt='a screenshot of the game'
            style={{
                height: 280,
                width: 500,
                margin: 10
            }}
        />
        </div>
        <p className="thesis-text">Roomba's Revenge was an entry in Hagenberg's 12th game jam with the theme
            of "Mean Machine." I was on the team VoidSupport as the lead 3D artist. The topic of the game jam
            was announced the night before it started, so that each team had an opportunity to brainstorm ideas.
            Starting on the following day, the game jam lasted 36 hours and consisted of 20 teams. All of the 
            entries can be seen and played {jam_link}
        </p>
        <p className="thesis-text">Our game has the player take control of a roomba that is intimidated by the
            owner's fancy new cleaning robot. The goal is to sabotage items throughout the home to distract 
            the new robot, break into the safe, and destroy the robot's warranty inside. Or something. I was
            never entirely clear on the plot. I made every 3D asset in the game (77 different models). The game
            was also released on one of the team member's {game_link}
        </p>
        <p className="thesis-text">I participated in the game jam on a laptop that I purchased while in Austria,
            so I don't have access to the Blender file with all the models. So until/unless I get a power adapter for
            EU-to-US outlets, I can't provide more detailed images of my content. But you can play the game,
            which is the next best thing.
        </p>
        </>
    )
}