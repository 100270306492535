import { useEffect, useState } from 'react'
import Wordle from './components/Wordle'
import './indexWordle.css'
import WordleHome from './components/WordleHome'

export default function AppWordle() {
  
  return (
    <div className="AppWordle">
      <h1 className='WordleHeader'>Wordle</h1>
      {<WordleHome/>}
    </div>
  )
}
