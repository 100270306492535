import './App.css';
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Thesis from "./pages/Thesis";
import Games from "./pages/Games";
import Programs from "./pages/Programs";

import Skeleslaps from "./pages/gamepages/Skeleslaps";
import UnrealRPG from "./pages/gamepages/UnrealRPG";
import Roomba from "./pages/gamepages/Roomba";
import Avast from "./pages/gamepages/Avast";
import DeepMagic from "./pages/gamepages/DeepMagic";

import Wordle from "./pages/programs/Wordle/AppWordle";

import "bootstrap/dist/css/bootstrap.min.css";
import Header from './pages/components/Header'; 
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header/>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="/About" element={<About/>}/>
          <Route path="/Gallery" element={<Gallery/>}/>
          <Route path="/Thesis" element={<Thesis/>}/>
          <Route path="/Games" element={<Games/>}/>
          <Route path="/Games/Skeleslaps" element={<Skeleslaps/>}/>
          <Route path="/Games/UnrealRPG" element={<UnrealRPG/>}/>
          <Route path="/Games/RoombasRevenge" element={<Roomba/>}/>
          <Route path="/Games/AvastYe" element={<Avast/>}/>
          <Route path="/Games/DeepMagic" element={<DeepMagic/>}/>
          <Route path="/Programs" element={<Programs/>}/>
          <Route path="/Programs/Wordle" element={<Wordle/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
