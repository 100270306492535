import React, { useEffect, useState } from 'react'
import '../indexWordle.css'
import Wordle from './Wordle'
import allwords from '../../../../assets/data/allwordsdb.json'
import wordslengths from '../../../../assets/data/wordsdb.json'

export default function WordleHome() {
    const [showWordle, setShowWordle] = useState(false)
    const [solution, setSolution] = useState('')
    const [length, setLength] = useState(5)
    const [attempts, setAttempts] = useState(6)

    const lengthOptions = [
        {value: 4, label: '4'},
        {value: 5, label: '5'},
        {value: 6, label: '6'},
        {value: 7, label: '7'},
        {value: 8, label: '8'},
        {value: 9, label: '9'},
        {value: 0, label: 'Random'}
    ]

    var words = allwords.words;
    var words4 = wordslengths.four;
    var words5 = wordslengths.five;
    var words6 = wordslengths.six;
    var words7 = wordslengths.seven;
    var words8 = wordslengths.eight;
    var words9 = wordslengths.nine;

    const lengthChange = (selectedOption) => {
        setLength(Number(selectedOption));
    };

    const attemptsChange = (selectedOption) => {
        setAttempts(Number(selectedOption));
    };

    const getSolution = () => {
        var word = ''
        switch(length) 
        {
            case (4):
                word = words4.at(Math.floor(Math.random()*words4.length));
                break;
            case (5):
                word = words5.at(Math.floor(Math.random()*words5.length));
                break;
            case (6):
                word = words6.at(Math.floor(Math.random()*words6.length));
                break;
            case (7):
                word = words7.at(Math.floor(Math.random()*words7.length));
                break;
            case (8):
                word = words8.at(Math.floor(Math.random()*words8.length));
                break;
            case (9):
                word = words9.at(Math.floor(Math.random()*words9.length));
                break;
            default:
                word = words.at(Math.floor(Math.random()*words.length));
                break;    
        }

        if (attempts == 0)
            attemptsChange(4 + Math.floor(Math.random()*5))

        setSolution(word)
        setShowWordle(true)
    }

    return (
        <>
        {!showWordle && (
            <div className='WordleLanding'>
                <p className='WordleText'>Welcome to my version of Wordle! It's basically like regular
                    Wordle, except you can choose words from 4-9 characters long and set a variable number
                    of attempts.
                </p>
                <p className='WordleText'>If you don't know how to play Wordle, the rules are simple.
                    You must guess a random word in a certain number attempts. After each attempt, the
                    letters used in the attempt will change color according to their appearance in the 
                    solution: Gray means it is not in the word, yellow means that it is but in a different
                    position, and green means that it is in that position.
                </p>
                <p className='WordleText'>Set your options below and play!</p>
                <div className='WordleOptions'>
                    <select value={length} onChange={e => lengthChange(e.target.value)}>
                        {lengthOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label + ' Letters'}
                            </option>
                        ))}
                    </select>
                    <select value={attempts} onChange={e => attemptsChange(e.target.value)}>
                        {lengthOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label + ' Attempts'}
                            </option>
                        ))}
                    </select>
                </div>
                <button onClick={() => getSolution()} className="button">Play</button>

            </div>
        )}

        {showWordle && <Wordle solution={solution} attempts={attempts} setShowWordle={setShowWordle}/>}
        </>
    )
}