import React from 'react'
import '../indexWordle.css'

export default function Modal({ isCorrect, solution, turn, setShowModal, setShowWordle }) {
  
  const toHome = () =>
  {
    setShowModal(false);
    setShowWordle(false);
  }
  
  return (
    <div className="WordleModal">
      {isCorrect && (
        <div>
          <span className="close" onClick={() => {toHome()}}>&times;</span>
          <h1>You Win!</h1>
          <p className="solution">{solution}</p>
          <p>You found the solution in {turn} guesses!</p>
        </div>
      )}
      {!isCorrect && (
        <div>
          <span className="close" onClick={() => {toHome()}}>&times;</span>
          <h1>Nice Try!</h1>
          <p className="solution">{solution}</p>
          <p>Better luck next time!</p>
        </div>
      )}
    </div>
  )
}
