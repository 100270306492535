import React from 'react'

export default function WordleRow({ guess, currentGuess, length }) {

  if (guess) {
    return (
      <div className="WordleRow past">
        {guess.map((l, i) => (
          <div key={i} className={l.color}>{l.key}</div>
        ))}
      </div>
    )
  }

  if (currentGuess) {
    let letters = currentGuess.split('')

    return (
      <div className="WordleRow current">
        {letters.map((letter, i) => (
          <div key={i} className="filled">{letter}</div>
        ))}
        {[...Array(length - letters.length)].map((_,i) => (
          <div key={i}></div>
        ))}
      </div>
    )
  }

  return (
    <div className="WordleRow">
      {[...Array(length)].map((e, i) => 
        <div key={i}></div>
    )}
    </div>
  )
  
}
