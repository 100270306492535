import bug from "../assets/images/Gallery/BYU/Bug.jpg"
import lego from "../assets/images/Gallery/BYU/Lego.jpg"
import piggy from "../assets/images/Gallery/BYU/motionBlur.mp4"

import skelerun from "../assets/images/Games/SkeleRun.gif"
import headstones from "../assets/images/Gallery/DeepMagic/Headstones.png"
import spawn from "../assets/images/Gallery/DeepMagic/SkeleSpawn.gif"
import skelestand from "../assets/images/Gallery/DeepMagic/SkeleStand.gif"
import skelewalk from "../assets/images/Gallery/DeepMagic/SkeleWalk.gif"
import wizard from "../assets/images/Gallery/DeepMagic/WizardEyes.png"

import dana1 from "../assets/images/Gallery/Other/Dana.png"
import dana2 from "../assets/images/Gallery/Other/DanaPose.png"
import dana3 from "../assets/images/Gallery/Other/CelShadingAdept.png"

import kael1 from "../assets/images/Gallery/Other/Kaelan.png"
import kael2 from "../assets/images/Gallery/Other/KaelanArmor2H.png"
import kael3 from "../assets/images/Gallery/Other/KaelanBrawler.png"
import kael4 from "../assets/images/Gallery/Other/Idle.gif"
import kael5 from "../assets/images/Gallery/Other/KaelAttack.gif"
import kael6 from "../assets/images/Gallery/Other/KaelCombo.gif"

import shiza1 from "../assets/images/Gallery/Other/Drawn.gif"
import shiza2 from "../assets/images/Gallery/Other/Sheathed.gif"
import shiza3 from "../assets/images/Gallery/Other/Combo.gif"

import slime from "../assets/images/Gallery/Other/SpiderSlime.gif"
import dummy from "../assets/images/Gallery/Other/Dummy.png"
import equipment from "../assets/images/Gallery/Other/KaelEquipment.png"

import "./Gallery.css"
import "./Home.css"

export default function Gallery() {
    return (
        <>
            <div className="gallery-stripe">
                <h1 className="gallery-header1">Gallery</h1>
                <h2 className='home-header2'>This a far from exhaustive collection of modeling, animation,
                     and FX projects that I've worked on. I've worked on numberous projects over the year, 
                     on my own and as a part of a group, and it would be infeasible at best to hunt down everything
                     I've ever made. Most images are screenshots from Blender, rather than proper renders
                     or in-engine for simplicity, so image quality is not as high as it could be.</h2>
            </div>
            <h2 className="gallery-header2" style={{backgroundColor: "lightgray"}}>BYU Projects</h2>
            <div className="gallery-image" style={{backgroundColor: "lightgray"}}>
            <img
                src={bug}
                alt='Final project for whatever class determined if you got accepted into the animation program. The prompt was: bug'
                style={{
                    height: '30%',
                    width: '30%',
                    margin: 15
                }}
            />
            <img
                src={lego}
                alt='First project for pre-animation class. The prompt was: make lego man'
                style={{
                    height: '30%',
                    width: '30%',
                    margin: 15
                }}
            />
            </div>
            <div className="gallery-video" style={{backgroundColor: "lightgray"}}>
                <video src={piggy}
                width='50%'
                height='50%'
                margin='30px'
                controls></video>
            </div>

            <h2 className="gallery-header2">Deep Magic</h2>
            <div className="gallery-image">
            <img
                src={skelerun}
                alt='Running animation for skeletons in Deep Magic'
                style={{
                    height: '20%',
                    width: '20%',
                    margin: 20
                }}
            />
            <img
                src={skelestand}
                alt='Standing animation for skeletons in Deep Magic'
                style={{
                    height: '22%',
                    width: '22%',
                    margin: 20
                }}
            />
            <img
                src={skelewalk}
                alt='Walking animation for skeletons in Deep Magic'
                style={{
                    height: '22%',
                    width: '22%',
                    margin: 20
                }}
            />
            </div>
            <div className="gallery-image">
            <img
                src={spawn}
                alt='Spawning animation for skeletons in Deep Magic'
                style={{
                    height: '25%',
                    width: '25%',
                    margin: 20
                }}
            />
            <img
                src={headstones}
                alt='Headstones for the spawning area for skeletons in Deep Magic'
                style={{
                    height: '20%',
                    width: '20%',
                    margin: 20
                }}
            />
            <img
                src={wizard}
                alt='The wizard in engine with glowing eye effect'
                style={{
                    height: '20%',
                    width: '20%',
                    margin: 20
                }}
            />
            </div>

            <h2 className="gallery-header2" style={{backgroundColor: "lightgray"}}>Miscellaneous</h2>
            <div className="gallery-image" style={{backgroundColor: "lightgray"}}>
            <img
                src={dana1}
                alt='A girl'
                style={{
                    height: '14%',
                    width: '14%',
                    margin: 20
                }}
            />
            <img
                src={dana2}
                alt='A girl with knives coming out of her limbs'
                style={{
                    height: '16%',
                    width: '16%',
                    margin: 20
                }}
            />
            <img
                src={dana3}
                alt='A girl with cel shading and 2 different outfits'
                style={{
                    height: '35%',
                    width: '35%',
                    margin: 20
                }}
            />
            </div>

            <div className="gallery-image" style={{backgroundColor: "lightgray"}}>
            <img
                src={shiza1}
                alt='A combat stance with sword drawn'
                style={{
                    height: '22%',
                    width: '22%',
                    margin: 20
                }}
            />
            <img
                src={shiza2}
                alt='A combat stance with sword sheathed'
                style={{
                    height: '25%',
                    width: '25%',
                    margin: 20
                }}
            />
            <img
                src={shiza3}
                alt='An attack combo with sword sheathed'
                style={{
                    height: '25%',
                    width: '25%',
                    margin: 20
                }}
            />
            </div>

            <div className="gallery-image" style={{backgroundColor: "lightgray"}}>
            <img
                src={kael1}
                alt='A guy'
                style={{
                    height: '28%',
                    width: '28%',
                    margin: 20
                }}
            />
            <img
                src={kael2}
                alt='The same guy with better armor and a big mace'
                style={{
                    height: '18%',
                    width: '18%',
                    margin: 20
                }}
            />
            <img
                src={kael3}
                alt='The same guy again but with no shirt'
                style={{
                    height: '15%',
                    width: '15%',
                    margin: 20
                }}
            />
            </div>

            <div className="gallery-image" style={{backgroundColor: "lightgray"}}>
            <img
                src={kael4}
                alt='An idle animation for that guy'
                style={{
                    height: '22%',
                    width: '22%',
                    margin: 20
                }}
            />
            <img
                src={kael5}
                alt='A single attack animation'
                style={{
                    height: '25%',
                    width: '25%',
                    margin: 20
                }}
            />
            <img
                src={kael6}
                alt='An attack combo '
                style={{
                    height: '25%',
                    width: '25%',
                    margin: 20
                }}
            />
            </div>

            <div className="gallery-image" style={{backgroundColor: "lightgray"}}>
            <img
                src={slime}
                alt='a spidery slime thing'
                style={{
                    height: '22%',
                    width: '22%',
                    margin: 20
                }}
            />
            <img
                src={dummy}
                alt='A combat dummy'
                style={{
                    height: '20%',
                    width: '20%',
                    margin: 20
                }}
            />
            <img
                src={equipment}
                alt='An array of hammers and maces'
                style={{
                    height: '28%',
                    width: '28%',
                    margin: 20
                }}
            />
            </div>
        </>
    )
}