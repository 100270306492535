import bikeRef from "../assets/images/Thesis/BikeReference2.jpg";
import poster from "../assets/images/Thesis/ThesisConferencePoster.png";
import avatars from "../assets/images/Thesis/UserAndAvatars.jpg";
import mask from "../assets/images/Thesis/VO2Master.jpg";
import scene from "../assets/images/Thesis/scene.png"
import pdf from "../assets/images/Thesis/ThesisPresentation.pdf"
import video from "../assets/videos/thesisVideo.mp4"

import "./Thesis.css";

var university_link = <a href="https://pure.fh-ooe.at/en/studentTheses/exhausted-avatars-in-virtual-reality-and-the-proteus-effect" target="_blank">here.</a>
var presentation_link = <a href={pdf} target="_blank">here.</a>
var conference_link = <a href={poster} target="_blank">Here</a>

export default function Thesis() {
    return (
        <>
        <div className="thesis-stripe">
            <h1 className="thesis-header1">Exhausted Avatars and the Proteus Effect</h1>
        </div>
        <div className="thesis-image">
        <img
            src={avatars}
            alt='A user on a stationary bicycle and all four avatar variations'
            style={{
                height: 350,
                width: 365,
                margin: 10
            }}
        />
        </div>
        <h3 className="thesis-text"> For my Master's Thesis I sought to further explore the Proteus Effect. The Proteus Effect is, essentially, the phenomenon in which the appearance 
            of an individual's avatar may influence their behavior within a virtual space. While this primarily relates to social behaviors, other studies have found that virtual 
            appearance can affect physical performance as well. My thesis examined how sweaty and non-sweaty avatar appearances could influence performance during a cycling exercise. </h3>
        <h3 className="thesis-text"> To accomplish this, I utilized a bicycle where the front and back wheels were replaced with the KICKR Core and KICKR Climb, respectively.
            While the KICKR Climb was not used for any particular features, the KICKR Core was used to record relevant variables such as pedaling frequency as well as change pedaling
            resistances for high and low intensity conditions. The virtual scene was created using Unity and interacted with via an HTC Vive headset and tracker, the latter of which 
            was attached to the user's lower leg. In addition, participants wore the VO2Master, in order to record respiratory data, such as VO2 Max. Heart rate was also recorded and
            managed using the same application as the KICKR equipment.
        </h3>
        <div className="thesis-image">
        <img
            src={bikeRef}
            alt='The bicycle used in the study, with the KICKR equipment replacing the wheels'
            style={{
                height: '30%',
                width: '30%',
                margin: 15
            }}
        />
        <img
            src={mask}
            alt='The VO2 Master used to record respiratory data'
            style={{
                height: '30%',
                width: '30%',
                margin: 15
            }}
        />
        </div>
        <h3 className="thesis-text"> The Unity scene consisted of a plain exercise room, with the user's avatar on a bicycle model in front of a virtual mirror, so that they could
            always see their avatar during the exercise. The user's head and pedaling motions were tracked, though their hands were assumed to always be gripping the handlebars.
            A total of 17 participants were studied.
        </h3>
        <div className="thesis-image">
        <img
            src={scene}
            alt='The Unity scene'
            style={{
                height: '50%',
                width: '50%',
                margin: 15
            }}
        />
        </div>
        <h3 className="thesis-text"> Of all the measures, 3 were found to have statistically significant effects depending on both the intensity of the exercise and the appearance
            of the avatar. Pedaling cadence decreased at high intensity when embodying the sweaty versus non-sweaty avatar, while perception of effort and VO2 Max levels increased
            at low intensity when embodying the sweaty avatar. These results do not align with previous studies which found a decreased perception of effort when embodying a sweaty
            avatar. This discrepancy could be a result of different study conditions, since this one had shorter conditions and separate conditions for low and high intensity.
        </h3>
        <h3 className="thesis-text"> Since I think the university technically owns my thesis, I am unsure if I am allowed to post the complete paper here. The full paper should be
            available in the university's library, and the abstract is available {university_link}
        </h3>
        <h3 className="thesis-text">The presentation for my thesis defense can be seen {presentation_link}</h3>
        <h3 className="thesis-text">{conference_link} is a poster made for a mock tech conference roughly halfway through development of my thesis.</h3>
        <h3 className="thesis-text">Below is a video made around the time of the above mentioned conference, so pedals, foot tracking, and textures are incomplete.</h3>
        <div className="thesis-video">
            <video src={video}
            width='50%'
            height='50%'
            margin='30px'
            controls></video>
         </div>
        </>
    )
}