import React, { useEffect, useState } from 'react'

export default function Keypad({ usedKeys, handleKeyup, isLocked }) {
  const topRow = ["q","w","e","r","t","y","u","i","o","p"]
  const middleRow = ["a","s","d","f","g","h","j","k","l"]
  const bottomRow = ["z","x","c","v","b","n","m"]
  const pointerEvents = isLocked ? "none" : "auto"

  return (
    <div className="keypad">
      {topRow.map(l => {
        const color = usedKeys[l]
        return (
          <div key={l} className={color} style={{pointerEvents: pointerEvents}} onClick={() => handleKeyup(l)}>{l}</div>
        )
      })}
      <br/>
      {middleRow.map((letter) => {
        const color = usedKeys[letter]
        return (
          <div key={letter} className={color} style={{pointerEvents: pointerEvents}} onClick={() => handleKeyup(letter)}>{letter}</div>
        )
      })}
      <br/>
      <div key="Enter" style={{width: "15%", fontSize:"13px", pointerEvents: pointerEvents}} onClick={() => handleKeyup("Enter")}>Enter</div>
      {bottomRow.map((letter) => {
        const color = usedKeys[letter]
        return (
          <div key={letter} className={color} style={{pointerEvents: pointerEvents}} onClick={() => handleKeyup(letter)}>{letter}</div>
        )
      })}
      <div key="Backspace" style={{width: "15%", pointerEvents: pointerEvents}} onClick={() => handleKeyup("Backspace")}>⌫</div>
    </div>
  )
}
