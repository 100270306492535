import './Header.css';

export default function Header()
{
    return (
        <header className='sticky-header'>
            <a className='menu-item' href='/'>Home</a>
            <a className='menu-item' href='/About'>About</a>
        </header>
    )
}