import video1 from "../../assets/videos/SkeleslapsInitial.mp4"
import video2 from "../../assets/videos/SkeleslapsUpdate.mp4"
import pic from "../../assets/images/Games/SkeleslapsTitle.png"

import "../Thesis.css"

var game_link = <a href="https://nodachi13.itch.io/skeleslaps?secret=6ohHxkWAeeWI87agGqText7WP4" target="_blank">itch.io page.</a>

export default function Skeleslaps()
{
    return(
        <>
        <div className="thesis-stripe">
            <h1 className="thesis-header1">Skeleslaps</h1>
        </div>
        <div className="thesis-image">
        <img
            src={pic}
            alt='The title screen for the game'
            style={{
                height: 250,
                width: 500,
                margin: 10
            }}
        />
        </div>
        <p className="thesis-text">Skeleslaps is a game that I made for my advanced graphics class during
            my bachelor's. As I recall, the initial assignment was just to implement a game using a 3D model
            we created for an earlier assignment and have user input of some kind. I finished that pretty 
            quickly, but couldn't stop myself from adding more and more. The title is just what one of my
            friends jokingly told me to call it, and since I never officially published or anything, I had
            no reason to come up with anything else. Also I thought it was funny.
        </p>
        <p className="thesis-text">The game has one playable character and three enemy types. There are
            also three game modes. Clear Mode requires you to defeat 100 skeletons, Surviaval Mode requires
            you to survive for 3 minutes, and Endless Mode is what it sounds like. Different enemy types start
            showing up at different times. Hardcore mode make enemies spawn more frequently and all types spawn
            from the beginning. Points are determined by number of skeletons killed per swing, where the 
            total is the sum of each skeleton's value multiplied by the number killed (Ex: killing 3 basic
            skeletons with one swing = (1+1+1)*3 = 9). This strongly encourages careful timing and placement.
        </p>
        <div className="thesis-video">
            <video src={video1}
            width='50%'
            height='50%'
            margin='30px'
            controls></video>
        </div>
        <p className="thesis-text">After the class I continued adding more to the game for a while. I had
            considered trying to make it into a full-fledged game to publish, but I was busy at the time
            and I just never got around to it. At any rate, I added another playable character: a mage, who
            has less health, takes less damage from poison, and has a special burst ability. I also added
            new enemy types: red skeletons, which split into two upon being attacked, and a lich which can
            summon shadow skeletons that slow the player down on contact. There are also statistics, showing
            things such as high scores, most points for a single attack, etc.
        </p>
        <div className="thesis-video">
            <video src={video2}
            width='50%'
            height='50%'
            margin='30px'
            controls></video>
        </div>
        <p className="thesis-text">As mentioned above, I never fully finished developing the game. I never
            made a polished build of the updated features, and the current version has bugs and randomly
            crashes. Changing computers and needed to use more recent versions of Unreal also make working
            on it more difficult. However, I do have a working build of the initial version on my {game_link} I 
            have little experience with itch and only just made my profile for this site, so I'm not 
            entirely sure that the download works. Hopefully it does and/or someone will let me know.
        </p>
        </>
    )
}